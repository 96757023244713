import React from 'react';
import { H2, P } from '@dnb/eufemia/elements';
import { Feedback } from '@dnb/feedback';
import Head from '@dnb/helmet';
import { Link } from '@dnb/link';
import { SimpleHeader } from '@dnb/header';
import FeedbackModule from '../../components/FeedbackModule';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>
      <SimpleHeader title="Feedback form demo (2/2)" />

      <H2>Lorem ipsum</H2>

      <P>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum
        velit non risus rhoncus eleifend. Nulla ut elit vel arcu feugiat
        commodo. Suspendisse fringilla a felis placerat pellentesque.
        Pellentesque porttitor mi vitae rutrum dapibus. Quisque vitae aliquam
        ligula. Curabitur sit amet nisl ipsum. Maecenas sed metus ante. Praesent
        euismod fermentum leo, eu ullamcorper est euismod in. Nam tempor massa
        sem, eget dictum diam commodo sit amet. Quisque vulputate malesuada
        vulputate. Proin suscipit dolor sit amet tortor malesuada, a faucibus
        erat accumsan.
      </P>

      <FeedbackModule
        feedbackId="1"
        feedbackQuestion="Do you want to report an issue?"
        likeButtonText="Yes"
        dislikeButtonText="No"
      >
        <Feedback.Question
          text="Want to add something? (Optional)"
          questionId="additional"
        />
      </FeedbackModule>

      <Link to="/feedback-form-demo" right="1rem">
        Previous example
      </Link>
    </>
  );
}

export default PageIndex;
